import React from "react";
import "./Details.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import SwiperCore, { Autoplay } from "swiper";

function Details(props) {
  SwiperCore.use([Autoplay]);
  let sliderImg;
  if (props.slider === "1") {
    sliderImg = (
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide> {props.image1} </SwiperSlide> 
      </Swiper>
    );
  } else if (props.slider === "2") {
    sliderImg = (
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide> {props.image1} </SwiperSlide> 
        <SwiperSlide> {props.image2} </SwiperSlide> 
      </Swiper>
    );
  }
  return (
    <>
      <div className="details">
        <div className="detailsImage"> {sliderImg} </div> 
        <div className="detailsDetail">
           
          {/* <div className="detailsSubHeader">
                        <h2> {props.header} </h2>
                      </div> */} 
          <div className="detailsTable">
            <table className="table1">
              <tbody>
                <tr>
                  <th> Usage / Application </th>
                  <td>REVERSE OSMOSIS</td>
                </tr> 
                <tr>
                  <th> Input Voltage </th>
                  <td>{props.inpVoltage}</td>
                </tr> 
                <tr>
                  <th> Frequency </th>
                  <td>{props.frequency}</td>
                </tr> 
                <tr>
                  <th> Output Voltage </th>
                  <td>{props.outVoltage}</td>
                </tr> 
                <tr>
                  <th> Max Output Current </th>
                  <td> {props.maxOutCurrent}</td>
                </tr> 
                <tr>
                  <th> {props.headProtection} </th>
                  <td>{props.protection}</td>
                </tr> 
              </tbody> 
            </table> 
            {/* <table className="table2">
                          <tr>
                            <th> Usage / Application </th> <td> Reverse Osmosis </td>
                          </tr>
                          <tr>
                            <th> Usage / Application </th> <td> Reverse Osmosis </td>
                          </tr>
                          <tr>
                            <th> Usage / Application </th> <td> Reverse Osmosis </td>
                          </tr>
                          <tr>
                            <th> Usage / Application </th> <td> Reverse Osmosis </td>
                          </tr>
                          <tr>
                            <th> Usage / Application </th> <td> Reverse Osmosis </td>
                          </tr>
                          <tr>
                            <th> Usage / Application </th> <td> Reverse Osmosis </td>
                          </tr>
                        </table> */} 
          </div> 
        </div> 
      </div> 
    </>
  );
}

export default Details;
