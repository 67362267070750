import React from "react";
import Card from "./Card/Card";
import "./WhoAreWe.css";
import {
  FcFactory,
  FcConferenceCall,
  FcPlanner,
  FcBusinessman,
  FcDiploma2,
} from "react-icons/fc";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";

function WhoAreWe() {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <div className="whoAreWe ">
        <div className="whoAreWeHeader center">
          <h1>Who Are We....</h1>
        </div>
        <div className="whoAreWeBody">
          <Swiper
            modules={[Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <Card
                logo={<FcFactory size={"3rem"} />}
                heading="Buisness Type"
                details="Manufacturer and Exporter"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                logo={<FcBusinessman size={"3rem"} />}
                heading="Company CEO"
                details="Yash Ramoliya"
              />
            </SwiperSlide>

            <SwiperSlide>
              <Card
                logo={<FcPlanner size={"3rem"} />}
                heading="Year Of Establishment"
                details="2020"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                logo={<FcConferenceCall size={"3rem"} />}
                heading="No. of Employees"
                details="30-35 Person"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                logo={<FcDiploma2 size={"3rem"} />}
                heading="GST Number"
                details="24BGFPH4397R1ZP"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default WhoAreWe;
