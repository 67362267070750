import React from "react";
import {
  MdAlternateEmail,
  MdPhoneIphone,
  MdOutlineLocationOn,
} from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import "./Contact.css";

function ContactAddress() {
  const map = "https://goo.gl/maps/ztYjSCwDAgiEQcND8";

  return (
    <div>
      <div className="perName center">
        <FaRegUserCircle color="red" size={"1.8rem"} />
        Yash Ramoliya(CEO)
      </div>
      <div className="perNumber center">
        <MdPhoneIphone color="red" size={"1.8rem"} />
        <a href="tel:+918460728151"> +91 &nbsp; 8460728151 </a>
      </div>
      <div className="perMail center">
        <MdAlternateEmail color="red" size={"1.8rem"} /> &nbsp;
        <a href="mailto:international.maruti16@gmail.com">
          international.maruti16 @gmail.com
        </a>
      </div>
      <div className="perAddress center">
        <MdOutlineLocationOn color="red" size={"1.8rem"} />
        <a href={map}>
          &nbsp; GIDC Plot No.464 / 156, <br /> &nbsp; Behind Udyognagar, Police
          <br /> &nbsp; Station, Shanker Tekri, Jamnagar, <br /> &nbsp; Gujarat
          - 361004.
        </a>
      </div>
    </div>
  );
}

export default ContactAddress;
