import React, { useState, useRef } from "react";
import "../Contact.css";
import "./ContactForm.css";
import { FiSend } from "react-icons/fi";
import validator from "validator";
import emailjs from "emailjs-com";

function ContactForm() {
  const forms = useRef();
  const serviceid = process.env.REACT_APP_SSID;
  const tempid = process.env.REACT_APP_TEMPID;
  const usid = process.env.REACT_APP_USID;

  const [form, setForm] = useState({
    fullname: "",
    number: "",
    email: "",
    subject: "",
    message: "",
  });
  const [alertMess, setAlertMess] = useState({
    namemess: "",
    nummess: "",
    emailmess: "",
    submess: "",
    messmess: "",
  });
  const handleClick = (e) => {
    e.preventDefault();

    const addNote = () => {
      emailjs.sendForm(serviceid, tempid, forms.current, usid).then();
    };
    if (form.fullname.length < 5) {
      setAlertMess({
        namemess: "Full Name can't have 5 letters only",
        nummess: "",
        emailmess: "",
        submess: "",
        messmess: "",
      });
    } else if (form.fullname.length > 15) {
      setAlertMess({
        namemess: "Full Name can't have more than 15 letters",
        nummess: "",
        emailmess: "",
        submess: "",
        messmess: "",
      });
    } else if (!validator.isMobilePhone(form.number, "any")) {
      setAlertMess({
        namemess: "",
        nummess: "Enter Valid Phone Number",
        emailmess: "",
        submess: "",
        messmess: "",
      });
    } else if (!validator.isEmail(form.email)) {
      setAlertMess({
        namemess: "",
        nummess: "",
        emailmess: "Enter a valid Email",
        submess: "",
        messmess: "",
      });
    } else if (form.subject.length < 15) {
      setAlertMess({
        namemess: "",
        nummess: "",
        emailmess: "",
        submess: "Subject can't have less than 15 letters",
        messmess: "",
      });
    } else if (form.message.length < 30) {
      setAlertMess({
        namemess: "",
        nummess: "",
        emailmess: "",
        submess: "",
        messmess: "Message can't have less than 30 letters",
      });
    } else {
      addNote();
      setAlertMess({
        namemess: "",
        nummess: "",
        emailmess: "",
        submess: "",
        messmess: "",
      });

      setForm({
        fullname: "",
        number: "",
        email: "",
        subject: "",
        message: "",
      });
      alert("Query Sent");
    }
  };
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setAlertMess({
      namemess: "",
      nummess: "",
      emailmess: "",
      submess: "",
      messmess: "",
    });
  };
  return (
    <>
      <h3> Your Queries </h3>
      <div className="formBody">
        <form ref={forms}>
          <div className="formName">
            FULL NAME: &nbsp; &nbsp;
            <input
              type="text"
              name="fullname"
              value={form.fullname}
              placeholder="enter full name"
              id="fullname"
              onChange={onChange}
              required
            />
          </div>
          <div className="alert"> {alertMess.namemess} </div>
          <div className="formNumber">
            PHONE NUMBER: &nbsp;
            <input
              type="tel"
              name="number"
              value={form.number}
              placeholder="enter country code first"
              id="number"
              onChange={onChange}
              required
            />
          </div>
          <div className="alert"> {alertMess.nummess} </div>
          <div className="formEmail">
            E - MAIL: &nbsp; &nbsp;
            <input
              type="email"
              name="email"
              value={form.email}
              placeholder="enter email"
              id="email"
              onChange={onChange}
              required
            />
          </div>
          <div className="alert"> {alertMess.emailmess} </div>
          <div className="formSubject">
            SUBJECT: &nbsp; &nbsp;
            <input
              type="text"
              name="subject"
              value={form.subject}
              placeholder="enter query subject"
              id="subject"
              onChange={onChange}
              required
            />
          </div>
          <div className="alert"> {alertMess.submess} </div>
          <div className="formMessage">
            MESSAGE: &nbsp; &nbsp;
            <textarea
              name="message"
              id="message"
              value={form.message}
              placeholder="enter message here"
              onChange={onChange}
              cols="30"
              required
            ></textarea>
          </div>
          <div className="alert"> {alertMess.messmess} </div>
          <div className="formSubmit">
            <button
              // disabled={form.fullname.length < 5 || form.fullname.length > 15 || !(validator.isMobilePhone(form.number, 'any')) || !(validator.isEmail(form.email)) || form.subject.length < 15 || form.message.length < 30}
              type="submit"
              onClick={handleClick}
            >
              Submit &nbsp; <FiSend color="#ffffff" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactForm;
