import React from "react";
import Details from "./Details/Details";
import "./ProductDetails.css";
import Img01 from "../../../assets/Products/001_orgn.jpg";
import Img02 from "../../../assets/Products/002_orgn.jpg";
import Img04 from "../../../assets/Products/004_orgn.jpg";
import Img041 from "../../../assets/Products/0041_orgn.jpg";

function ProductDetails(props) {
  let prodDetails;
  if (props.prodnumber === "1") {
    prodDetails = (
      <Details
        slider="2"
        image1={<img src={Img01} alt="productImage" />}
        image2={<img src={Img041} alt="productImage" />}
        inpVoltage="120V-360V VOLT AC, +15% TOLERANCE"
        frequency="50Hz"
        outVoltage="24 VOLT DC, +4% TOLERANCE"
        maxOutCurrent="2.5 AMP"
        headProtection="Protection Include"
        protection=" SHORT CIRCUIT, OVERLOAD, OVERTEMPERATURE"
      />
    );
  } else if (props.prodnumber === "2") {
    prodDetails = (
      <Details
        slider="1"
        image1={<img src={Img02} alt="productImage" />}
        inpVoltage="230 VOLT AC, +15% TOLERANCE"
        frequency="50Hz"
        outVoltage="24 VOLT DC, +5% TOLERANCE"
        maxOutCurrent="2.5 AMP"
        headProtection="Protection Include"
        protection=" SHORT CIRCUIT, OVERLOAD, OVERTEMPERATURE"
      />
    );
  } else if (props.prodnumber === "3") {
    prodDetails = (
      <Details
        slider="1"
        image1={<img src={Img02} alt="productImage" />}
        inpVoltage="230 VOLT AC, +15% TOLERANCE"
        frequency="50Hz"
        outVoltage="36 VOLT DC, +5% TOLERANCE"
        maxOutCurrent="2.0 AMP"
        headProtection="Protection Include"
        protection=" SHORT CIRCUIT, OVERLOAD, OVERTEMPERATURE"
      />
    );
  } else if (props.prodnumber === "4") {
    prodDetails = (
      <Details
        slider="1"
        image1={<img src={Img04} alt="productImage" />}
        inpVoltage="24 VOLT DC"
        frequency="-"
        outVoltage="4 WATT UV LAMP & 24 VOLT SOLENOID VALVE"
        maxOutCurrent="-"
        headProtection="Protection Include"
        protection=" SHORT CIRCUIT, OVERLOAD, OVERTEMPERATURE"
      />
    );
  }
  return (
    <>
      <div className="detailsCont">
        <div className="detailsHeader">
          <h1> {props.catHeader} </h1>
          <h3>{props.catDetails}</h3>
        </div>
        {prodDetails}
      </div>
    </>
  );
}

export default ProductDetails;
