import React from "react";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Footer.css";
function Footer() {
  return (
    <div>
      <div className="footerContainer">
        <div className="footerContent">
          <div className="footerHeader">
            <h1>Who are we....</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="footerProducts">
            <h1>
              <Link to="/products">Products</Link>
            </h1>
            <ul>
              <li>
                <Link to="/products/category1">Model - ATS-MI-240250</Link>
              </li>
              <li>
                <Link to="/products/category2">Model - YP-MI-240250</Link>
              </li>

              <li>
                <Link to="/products/category3">Model - YP-MI-360250</Link>
              </li>
              <li>
                <Link to="/products/category4">Model - ATS-M-UVB4W</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footerFooter">
          <div className="footerCopyright">
            Copyright © 2021{" "}
            <a href="https://www.themarutiinternational.com">
              Maruti International
            </a>
            . All Rights Reserved.
          </div>
          <div className="footerSocial">
            Follow Us On:
            {/* <a href="/">
              <FaInstagram size={"1.8rem"} color="#FF0173" />
            </a> */}
            <a
              href="https://www.facebook.com/Maruti-International-103656378566417/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook size={"1.8rem"} color="#1900FF" />
            </a>
            <a
              href="https://wa.me/message/W73RU77I2SYDF1"
              target="_blank"
              rel="noreferrer"
            >
              <FaWhatsapp size={"1.8rem"} color="#199E19" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
