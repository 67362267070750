import React from "react";
import "./WhyUs.css";
import WhyUsImg from "../../../assets/WhyUs.svg";

function WhyUs() {
  return (
    <div>
      <div className="whyUs center">
        <div className="whyUsImg center">
          <img src={WhyUsImg} alt="WhyUs" />
        </div>
        <div className="whyUsDetails">
          <div className="whyUsDetailsHeader">
            <h1> Why Choose Us ? </h1>
          </div>
          <div className="whyUsDetailsBody">
            <b> Need a Best Quality Products you can count here! </b> <br />
            Maruti International is a very powerful organization which help you
            to find out best quality as well as customized products.We thrive to
            deliver effective product solutions and steadfast support to enhance
            the overall productivity of our client’ s workplaces.
            <ul>
              <li> Customer Focused & Growth Oriented Management </li>
              <li> Quality-proven range</li>
              <li> Most Competitive Prices </li>
              <li> Continuously Upgraded Machinery & Equipment </li>
              <li> Products conform to international standards </li>
              <li> Rapid Communication </li>
              <li> Timely delivery & Wide distribution network </li>
              <li>Vast industrial experience</li>
              <li>Customization options</li>
              <li>
                Solid and clean Product and ongoing support you can count on
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
