import Category from "./Category/Category";
import "./Products.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Img01 from "../../assets/Products/001.png";
import Img02 from "../../assets/Products/002.png";
import Img04 from "../../assets/Products/004.png";

function Products() {
  return (
    <>
      <div className="prodCont">
        <Category
          categoryOne="Model - ATS-MI-240250"
          categoryOneDetail="SMD Based Technology with Best in Quality."
          categoryOneImage={Img01}
          categoryOneLink={
            <Link to="/products/category1">
              View Details &nbsp; <BsArrowRight color="red" />
            </Link>
          }
          categoryTwo="Model - YP-MI-240250"
          categoryTwoDetail="Heavy Duty Transformer."
          categoryTwoImage={Img02}
          categoryTwoLink={
            <Link to="/products/category2">
              View Details &nbsp; <BsArrowRight color="red" />
            </Link>
          }
        />{" "}
        <Category
          categoryOne="Model - YP-MI-360250"
          categoryOneDetail="Heavy Duty Transformer."
          categoryOneImage={Img02}
          categoryOneLink={
            <Link to="/products/category3">
              View Details &nbsp; <BsArrowRight color="red" />
            </Link>
          }
          categoryTwo="Model - ATS-M-UVB4W"
          categoryTwoDetail="SMD Based Technology with S.V. Control"
          categoryTwoImage={Img04}
          categoryTwoLink={
            <Link to="/products/category4">
              View Details &nbsp; <BsArrowRight color="red" />
            </Link>
          }
        />{" "}
      </div>{" "}
    </>
  );
}

export default Products;
