import React from "react";
import "./Card.css";
function Card(props) {
  return (
    <div>
      <div className="cardCont center">
        <div className="cardLogo">{props.logo}</div>
        <div className="cardDetails">
          <b>{props.heading}: </b>
          {props.details}
        </div>
      </div>
    </div>
  );
}

export default Card;
