import React from "react";
import Img1 from "../../assets/Products/001_orgn.jpg";
import Img2 from "../../assets/Products/002_orgn.jpg";
import Img4 from "../../assets/Products/004_orgn.jpg";
import Img41 from "../../assets/Products/0041_orgn.jpg";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper";
import "./Home.css";
import HomeDetails from "./HomeDetails/HomeDetails";
import WhyUs from "./WhyUs/WhyUs";
import WhoAreWe from "./WhoAreWe/WhoAreWe";

function Home() {
  SwiperCore.use([Autoplay]);

  return (
    <>
      <div className="homeContainer">
        <div className="homeSwiper">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <img src={Img1} alt="Img1" />
            </SwiperSlide>{" "}
            <SwiperSlide>
              <img src={Img2} alt="Img2" />
            </SwiperSlide>{" "}
            <SwiperSlide>
              <img src={Img4} alt="Img3" />
            </SwiperSlide>{" "}
            <SwiperSlide>
              <img src={Img41} alt="Img4" />
            </SwiperSlide>{" "}
          </Swiper>{" "}
        </div>{" "}
        <WhoAreWe />
        <HomeDetails />
        <WhyUs />
      </div>{" "}
    </>
  );
}

export default Home;
