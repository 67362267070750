import { Route, Routes } from "react-router";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Products from "./components/Products/Products";
import ProductDetails from "./components/Products/ProductDetails/ProductDetails";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Error404 from "./components/Error404/Error404";

function App() {
  return (
    <>
      <div className="mainContainer">
        <Routes></Routes>
        <Navbar />
        <div className="mainBody">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/products/category1"
              element={
                <ProductDetails
                  prodnumber="1"
                  catHeader="Model - ATS-MI-240250"
                  catDetails="SMP Based Technology with Best in Quality."
                />
              }
            />
            <Route
              path="/products/category2"
              element={
                <ProductDetails
                  prodnumber="2"
                  catHeader="Model - YP-MI-240250"
                  catDetails="SMP Based Technology with Heavy Duty Transformer."
                />
              }
            />
            <Route
              path="/products/category3"
              element={
                <ProductDetails
                  prodnumber="3"
                  catHeader="Model - YP-MI-360250"
                  catDetails="SMP Based Technology with Heavy Duty Transformer."
                />
              }
            />
            <Route
              path="/products/category4"
              element={
                <ProductDetails
                  prodnumber="4"
                  catHeader="Model - ATS-M-UVB4W"
                  catDetails="SMP Based Technology with Best Built Quality of ABS Material."
                />
              }
            />
            <Route path="/products/*" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
