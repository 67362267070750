import React, { useEffect } from "react";
import maruti from "../../assets/maruti.jpeg";
// import infra from "../../assets/infra.svg";
import mission from "../../assets/mission.svg";
import vision from "../../assets/vision.svg";
import AOS from "aos";
import "aos/dist/aos.css";

import "./About.css";
function About() {
  useEffect(() => {
    AOS.init({ easing: "ease-in-out", mirror: true });
  }, []);
  return (
    <div>
      <div className="abtCont">
        <div className="abtHeader">
          <h1>About Us</h1>
        </div>
        <div className="abt1">
          <div className="abtImg center" data-aos="fade-right">
            <img src={maruti} alt="logo" />
          </div>
          <div className="abtContent" data-aos="fade-left">
            <h1>About Maruti International</h1>
            <p>
              Maruti International was founded in 2020 with a mission to provide
              electro power solution by manufacturing of power products with its
              original brand name of “Maruti International”. Today in a very
              short time our Company has a great reputation as an expert
              manufacturer of various types of SMPS, U.V. Ballast and many more.
            </p>
          </div>
        </div>
        {/* <div className="abt2">
          <div className="abtImg center" data-aos="fade-right">
            <img src={infra} alt="logo" />
          </div>
          <div className="abtContent" data-aos="fade-left">
            <h1>Infrasructure</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem
              facilis, velit consequatur veritatis nihil accusantium maxime
              cupiditate dolor amet accusamus labore exercitationem?
              Exercitationem cupiditate nihil amet quae doloremque officia
              assumenda, dolorem nesciunt, id voluptate ad maiores vero? Nisi,
              laboriosam quibusdam!
            </p>
          </div>
        </div> */}
        <div className="abt3">
          <div className="abtImg center" data-aos="fade-right">
            <img src={mission} alt="logo" />
          </div>
          <div className="abtContent" data-aos="fade-left">
            <h1>Mission</h1>
            <p>
              Our mission is to provide the best in quality product in the
              national an international market through innovating and improving
              products and services with a fair price and performance ratio,
              thereby creating the maximum benefit to our customers.
            </p>
          </div>
        </div>
        <div className="abt4">
          <div className="abtImg center" data-aos="fade-right">
            <img src={vision} alt="logo" />
          </div>
          <div className="abtContent" data-aos="fade-left">
            <h1>Vision</h1>
            <p>
              Maruti International is committed to become the leading brand in
              standard products manufacturing and transform into an efficient
              and sustainable enterprise with well- balanced management.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
