import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.png";
import HamBurger from "./HamBurger";
import "./Navbar.css";

function Navbar() {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  return (
    <>
      <div className="navContainer">
        <Link
          className={
            splitLocation[1] === "" ? "navTitles activeNav" : "navTitles"
          }
          style={{ marginRight: "120px" }}
          to="/"
        >
          <div> Home </div>
        </Link>
        <Link
          className={
            splitLocation[1] === "about" ? "navTitles activeNav" : "navTitles"
          }
          to="/about"
        >
          <div> About </div>
        </Link>
        <Link to="/">
          <div className="navLogo">
            <img src={Logo} alt="Logo" />
          </div>
        </Link>
        <Link
          className={
            splitLocation[1] === "products"
              ? "navTitles activeNav"
              : "navTitles"
          }
          to="/products"
        >
          <div> Products </div>
        </Link>
        <Link
          className={
            splitLocation[1] === "contact" ? "navTitles activeNav" : "navTitles"
          }
          to="/contact"
          style={{ marginLeft: "120px" }}
        >
          <div> Contact Us </div>
        </Link>
        <div className="hamBurger">
          <HamBurger />
        </div>
      </div>
    </>
  );
}

export default Navbar;
