import React from "react";
import { Link } from "react-router-dom";
import error from "../../assets/error404.svg";
import "./Error404.css";
function Error404() {
  return (
    <div>
      <div className="errCont">
        <div className="errImg">
          <img src={error} alt="error" />
        </div>
        <div className="errLink">
            <Link to="/">Back to Homepage</Link>
        </div>
      </div>
    </div>
  );
}

export default Error404;
