import React, { useEffect } from "react";
import "./Category.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Category(props) {
  useEffect(() => {
    AOS.init({ easing: "ease-in-out", mirror: true });
  }, []);

  return (
    <>
      <div className="prodBody">
        <div className="prodCat1" data-aos="fade-right">
          <div className="catImage1">
            <div className="prodImage">
              <img src={props.categoryOneImage} alt="Product" />
            </div>
          </div>
          <div className="catDetail">
            <div className="catHead text">
              <h1>{props.categoryOne}</h1>
            </div>
            <div className="catBody text">{props.categoryOneDetail}</div>
            <div className="catLink ">{props.categoryOneLink}</div>
          </div>
        </div>

        <div className="prodCat2" data-aos="fade-left">
          <div className="catImage2">
            <div className="prodImage">
              <img src={props.categoryTwoImage} alt="Product" />
            </div>
          </div>

          <div className="catDetail">
            <div className="catHead text">
              <h1>{props.categoryTwo}</h1>
            </div>
            <div className="catBody text">{props.categoryTwoDetail}</div>
            <div className="catLink">{props.categoryTwoLink}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
