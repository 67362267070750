import React from "react";
import "./Contact.css";
import ContactAddress from "./ContactAddress";
import ContactForm from "./ContactForm/ContactForm";
function Contact() {
  return (
    <>
      <div className="contactContainer ">
        <div className="contactHeader center">
          <h3>Contact Us</h3>
        </div>
        <div className="contactBody center">
          <div className="contactAddress center">
            <ContactAddress />
          </div>
          <div className="contactForm center">
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
