import React from "react";
import "./HomeDetails.css"
import { FcEngineering, FcCustomerSupport } from "react-icons/fc";
import { GiMoneyStack } from "react-icons/gi";

function HomeDetails() {
  return (
    <div>
      <div className="homeDetails center">
        <div className="homeDetailsHeader">
          <h1>What We Provide....</h1>
        </div>
        <div className="homeDetailsBody center">
          <div className="technical center">
            <div className="technicalLogo center">
              <FcEngineering size={"4rem"} />
            </div>
            <div className="technicalDetails">
              With large years of experience we are leading in industry as we
              provide best service and support to our customers at one stop.
            </div>
          </div>
          <div className="cost center">
            <div className="costLogo center">
              <GiMoneyStack color="#009600" size={"4rem"} />
            </div>
            <div className="costDetails">
              We are direct manufacturer & Exporter, we can provide you products
              at very cost effective rates and of best quality.
            </div>
          </div>
          <div className="support center">
            <div className="supportLogo center">
              <FcCustomerSupport size={"4rem"} />
            </div>
            <div className="supportDetails">
              Our Premium Support services will help your technical or
              subject-matter expert staff more effectively.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDetails;
